@import url('https://fonts.googleapis.com/css?family=Syncopate');
@import url('https://fonts.googleapis.com/css?family=Abel');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import url('https://fonts.googleapis.com/css?family=Maven+Pro');
@import url('https://fonts.googleapis.com/css?family=Oxygen');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Inconsolata');

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html body {
	padding: 0;
	margin: 0;
	background-color: var(--white-colour);
	color: var(--black-color);

}

body,
.wrapper {
	margin: 0;
	padding: 0;
	min-height: 100vh;
}

.btn {
	color: var(--title-text);
	background-color: var(--title-background);
	border-color: var(--border-color);
}

.icon-cog {
	opacity: 0.6;
	color: var(--title-background);
}

.icon-cog:hover {
	color: var(--body-color);
	opacity: 1.0;
}

.btn-tool:hover {
	opacity: 1.0;
}

.btn-tool-prompt {
	width: 100%;
}

.btn-smalltool {
	opacity: 0.6;
}

.btn-smalltool:hover {
	opacity: 0.6;
	color: var(--white-colour)
}

.btn-themed-dark {
	color: var(--title-text);
	background-color: var(--title-background);
	border-color: var(--border-color);
	border-width: 0px;
}

.btn:hover {
	color: var(--title-text-alt);
	border-color: var(--border-color);
	background-color: var(--body-color);
	outline: none;
	box-shadow: none;
}

.btn:not(:disabled):not(.disabled):active,
.btn:focus,
.btn:active,
.btn.active {
	color: var(--title-text);
	border-color: var(--border-color);
	background-color: var(--title-background);
	outline: none;
	box-shadow: none;
}

.modal-content {
	background-color: var(--body-background);
	border-color: var(--border-color);
}

.eom-client {
	overscroll-behavior-y: contain;
	background-color: var(--client-background);
	overflow: hide;
	flex-wrap: nowrap;
	min-height: 100vh;
	max-height: 100vh;
	height: 100%;
	margin: 0px;
	z-index: 2;
}


.output-column {
	/* border: 3px solid var(--title-background); */
	flex-shrink: 1;
	flex-grow: 1;
	display: 'flex';
	flex-basis: 'row';
}

.stock-row {
	border: 1px solid var(--title-background);
	border-radius: 0.5em;
	width: 100%;
}

.stock-item {
	display: flex;
	flex-direction: row;
}

.eom-output {
	background-color: var(--client-background);
	--bs-gutter-x: 0rem;
	padding-left: 0px;
	margin-left: 0px;
}

.input-container {
	overflow-y: hidden;
	white-space: break-spaces;
	line-height: normal;
	color: var(--white-colour);
	background-color: var(--client-background);
	height: 20vh;
	max-width: 100vw;
	border: 3px solid var(--border-color);
	border-left: none;
	border-right: none;
	border-radius: 3px;
}

.input-form {
	width: 100%;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
}

.input-group-eom {
	height: 100%;
	flex-wrap: nowrap;
	background-color: var(--client-background);
}

.inputStatus {
	max-height: 1em;
}

.output-container {
	overflow-y: scroll;
	white-space: break-spaces;
	line-height: normal;
	color: var(--white-colour);
	background-color: var(--client-background);
	height: 100%;
	max-height: 80vh;
	max-width: 100vw;
	display: flex;
	flex-direction: row;
}

.output-container-max {
	overflow-y: scroll;
	white-space: break-spaces;
	line-height: normal;
	color: var(--white-colour);
	background-color: var(--client-background);
	height: 100%;
	max-height: 95vh;
	max-width: 100vw;
	display: flex;
	flex-direction: row;
}

.output-container-full {
	overflow-y: scroll;
	white-space: break-spaces;
	line-height: normal;
	color: var(--white-colour);
	background-color: var(--client-background);
	height: 98vh;
	max-width: 100vw;
}

.output-container-half {
	overflow-y: auto;
	white-space: break-spaces;
	line-height: normal;
	color: var(--white-colour);
	background-color: var(--client-background);
	max-height: 44vh;
	height: 100%;
	max-width: 100vw;
}

.custom-control-input:checked~.custom-control-label::before {
	color: var(--white-colour);
	border-color: var(--title-background-light);
	background-color: var(--body-color);
}


.custom-control-input:checked~.custom-control-label::after {
	color: var(--white-colour);
	border-color: var(--title-background-light);
	background-color: var(--body-color);
}

.custom-control-input:focus~.custom-control-label::before {
	border-color: var(--title-background-light) !important;
	box-shadow: 0 0 0 0.2rem var(---shadow2-color) !important;
}

.custom-control-input:checked~.custom-control-label::before {
	border-color: var(--title-background-light) !important;
	background-color: var(--body-color) !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
	border-color: var(--title-background-light) !important;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
	background-color: var(--body-text-color) !important;
	border-color: var(--body-text-color) !important;
}

.output-container-room {
	box-shadow: 0 30px 40px rgba(0, 0, 0, .1);
	overflow-y: scroll;
	line-height: normal;
	max-height: 20vh;
	max-width: 100vw;
}

.output-container-area {
	box-shadow: 0 30px 40px rgba(0, 0, 0, .1);
	overflow-y: scroll;
	line-height: normal;
	max-height: 80vh;
	max-width: 100vw;
}

.inputrow {
	display: block;
	background-color: black;
	max-width: 100vw;
	min-height: 18vh;
}

/* width */

::-webkit-scrollbar {
	width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
	box-shadow: none;
	/* //inset 0 0 5px var(--shadow2-color); */
	border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
	background: var(--title-background);
	border-radius: 10px;
}

.floatingdiv {
	width: 100%;
}



.form-no-padding {
	padding-right: 0px;
	padding-left: 0px;
	height: 100%;
}

#divBoxed {
	margin-top: 51px;
	;
	;
}

#divLogo {
	margin-top: 48px;
	margin-bottom: 0px;
	text-align: center;
}

.divTagLine {
	color: var(--tag-color);
	line-height: 25px;
	text-transform: none;
	letter-spacing: 4px;
	font-size: 16px;
	padding-left: 2px;
	padding-right: 2px;
	background-color: transparent;
	text-shadow: none;
	font-family: Abel;
	font-weight: normal;
	font-style: normal;
}

.divSiteTitle {
	font-family: Syncopate;
	font-size: 28px;
	line-height: 28px;
	color: var(--body-color);
	text-shadow: var(--shadow-color) 0px 3px 5px, var(--shadow2-color) 0px -5px 35px;
	letter-spacing: 4px;
	font-weight: normal;
	font-style: normal;
	text-decoration: none;
}

.eomLink {
	color: var(--body-color);
}

.list-eom {
	color: var(--body-color);
	list-style-type: none;
}

.eom1 {
	color: var(--body-color);
	padding-top: -15px;
	margin-top: -15px;
}

.eom2 {
	color: var(--body-color);
	padding-top: -5px;
}

#divNavBarTitle {
	font-family: Syncopate;
	font-size: 24px;
	line-height: 49px;
	color: var(--body-color);
	text-shadow: var(--shadow-color) 0px 3px 5px, var(--shadow2-color) 0px -5px 35px;
	letter-spacing: 4px;
	font-weight: normal;
	font-style: normal;
	text-transform: uppercase;
}

#divMainContent {
	font-size: 15px;
	line-height: 22px;
	font-family: 'Maven Pro', sans-serif;
	padding-top: 5px;
	padding-right: 30px;
	padding-bottom: 30px;
	padding-left: 30px;
}

#wrap {
	min-height: 100%;
}

html,
body {
	height: 100%;
}



/* Sticky footer styles
-------------------------------------------------- */

.footer {
	font-size: 13px;
	font-family: 'Maven Pro', sans-serif;
	background-repeat: repeat;
	position: relative;
	background-color: var(--white-colour);
	/* negative value of footer height */
	height: 310px;
	clear: both;
	padding-top: 10px;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 0px;
}

#divFooter a {
	color: var(--body-color);
	font-size: 13px;
	font-family: 'Maven Pro', sans-serif;
}

#divFooter h3 {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: normal;
	font-style: normal;
	font-size: 23px;
	line-height: 45px;
	color: rgb(0, 0, 0);
}

/* Set the fixed height of the footer here */

#push {
	height: 0px;
}

#appviewport {
	height: 100vh
}

/* Footer elements */

.text-content {
	padding-top: 30px;
	padding-right: 30px;
	padding-bottom: 0px;
	padding-left: 30px;
	font-size: 15px;
	line-height: 22px;
	font-family: 'Maven Pro', sans-serif;
}

.social_bookmarks a {
	font-size: smaller !important;
	text-transform: uppercase;
	letter-spacing: 1px;
	text-decoration: none;
	margin-right: 20px;
}

.copyright {
	font-size: smaller;
	letter-spacing: 1px;
	color: var(--white-colour)
}

.coloured-box {
	background-color: var(--white-colour);

	min-height: 60svh;
}

.coloured-box-aligned {
	background-color: var(--white-colour);
	text-align: left;
}

.remove-all-margin-padding {
	width: 100vw;
	position: relative;
	left: 49%;
	right: 49%;
	margin-left: -50vw;
	margin-right: -50vw;
}

.card-icon {
	margin-top: 20px;
	max-width: 100px;
	max-height: 100px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 40%;
}

.card {
	margin: 0 auto;
	/* Added */
	float: none;
	/* Added */
}

.bg-eom {
	background-image: linear-gradient(to left, var(--title-background-alt), var(--title-background));
}

.navbar-dark .navbar-nav .nav-link {
	padding-top: 3px;
	padding-bottom: 3px;
	color: var(--title-text);
}

code,
pre,
samp {
	font-family: Lucida Sans Typewriter, Roboto Mono, Lucida Console, monaco, Courrier, monospace;
	font-size: .85em;
	background-color: var(--black-color);
	color: var(--white-colour);
	margin: 10px;
	padding-left: 5px;
	max-width: 80vw;
	border-radius: 5px;
}

pre.prelog {
	background-color: var(--black-color);
	color: var(--white-colour);
	padding: 10px;
	height: auto;
	max-height: 500px;
	overflow: auto;
	word-break: normal !important;
	word-wrap: normal !important;
	white-space: prelog !important;
}

a {
	font-family: 'Maven Pro', sans-serif;
	color: var(--title-background);
}

a:hover,
a:visited,
a:link,
a:active {
	text-decoration: none;
}

a:hover {
	color: var(--body-color);
}

/* Shout Box */

.shout-box {
	margin-bottom: 28px;
	padding: 16px 45px 19px 29px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	overflow: hidden;
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 12px;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-color: var(--white-colour);
	border-right-color: var(--white-colour);
	border-bottom-color: var(--white-colour);
	border-left-color: var(--body-color);
}

.shout-box .btn-right {
	float: right;
	margin-top: 10px;
	margin-bottom: 10px;
}

.shout-box h2 {
	font: 23px/22px 'Source Sans Pro', sans-serif;
	color: var(--black-color);
	margin-top: 10px;
	margin-bottom: 10px;
}

.shout-box p {
	margin: 0;
}

.padded-card {
	margin: 5px;
}

.navbar-text {
	margin-top: 7.5px;
	margin-bottom: 7.5px;
	margin-right: 10px;
	display: inline-block;
	text-transform: capitalize;
}

.text-toogle[aria-expanded=false] .text-expanded {
	display: none;
}

.text-toogle[aria-expanded=true] .text-collapsed {
	display: none;
}

.hide-mail {
	display: none;
}

.show-mail {
	padding-right: 5px;
}

.kinks-ul-triple {
	list-style-type: none;
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
}

.kinks-ul-single {
	list-style-type: none;
}

table {
	table-layout: fixed;
}

th.first {
	width: 20%;
}

th.second {
	width: 15%;
}

th.third {
	width: 15%;
}

th.fourth {
	width: 50%;
}

.capitalize-text {
	text-transform: capitalize;
}

.dryLink {
	color: inherit;
	/* blue colors for links too */
	text-decoration: inherit;
	/* no underline */
}

.dropdown-item {
	color: var(--title-text) !important;
	background-color: var(--title-background);
}

.dropdown-item.active,
.dropdown-item:active {
	color: var(--colour-four);
	text-decoration: none;
	background-color: var(--title-background);
}

.dropdown-item:focus,
.dropdown-item:hover {
	color: var(--title-background);
	text-decoration: none;
	background-color: var(--body-text-color);
}

.card {
	background-color: var(--white-colour);
	color: unset;

}

.dropdown-menu {
	background-color: var(--title-background);
}

.dropdown-menu .divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
}

.dropdown-divider {
	color: var(--body-color);
	background-color: var(--title-background);
}

.divider2 {
	margin: 0;
	border-color: var(--border-color);
	border-style: solid;
	border-width: 5px;
}

.divider3 {
	margin: 2px;
	border-color: var(--border-color);
	border-style: solid;
	border-width: 2px;
}

.btn-tool {
	opacity: 0.8;
	width: 100%;
}

.btn-tool:hover {
	opacity: 1.0;
}

.sidenav {
	width: 350px;
	position: absolute;
	z-index: 1;
	right: 0;
	top: 85px;
	background-color: var(--title-background);
	color: var(--title-text);
	overflow-x: hidden;
	padding-top: 20px;
	padding-bottom: 20px;
	transition: 0.75s;
	border-radius: 0px 0Px 5px 15px;
}

.sidenavclient {
	width: 200px;
	position: absolute;
	right: 30px;
	padding-bottom: 20px;
	transition: 0.75s;
	z-index: 3;
	top: 0px;
}

.sidenav a {
	padding: 6px 8px 6px 16px;
	text-decoration: none;
	font-size: 25px;
	color: var(--title-text);
	display: block;
}

.sidenav a:hover {
	color: var(--body-text-color);
}

#profilename {
	color: var(--body-text-color);
	vertical-align: middle;
}

.avatar {
	max-width: 64px;
}

.avatar-offline {
	opacity: 0.4;
	filter: alpha(opacity=40);
	/* msie */
	background-color: #000;
}

.eomLeftBorders {
	overflow: auto;
	border: 2px solid var(--title-background-alt);
	border-top: none;
	border-bottom: none;
	border-right: none;
	border-radius: 3px;
	max-height: 100vh;
	justify-content: center;
}

.eomRightBorders {
	overflow: auto;
	border: 2px solid var(--title-background-alt);
	border-top: none;
	border-left: none;
	border-bottom: none;
	border-radius: 3px;
	max-height: 100vh;
	justify-content: center;

}

.limitWidth {
	max-width: 25%;
}

.eomIntervalBorderTop {
	border: 3px solid var(--title-background-alt);
	border-left: none;
	border-right: none;
	border-top: none;
	border-radius: 3px;
	--bs-gutter-x: 0;
	margin: 2px;
}

.eomIntervalBorderBottom {
	border: 3px solid var(--title-background-alt);
	border-left: none;
	border-right: none;
	border-top: none;
	border-radius: 3px;

}

.eomCharacter {
	color: var(--client-text);
	font-family: Roboto, sans-serif;
	justify-content: space-between;
}

.eomCharacterLeft {
	color: var(--client-text);
	background-color: var(--client-background);
	font-family: Roboto, sans-serif;
	font-size: 16px;
	line-height: normal;
	text-align: left;
}

.eomCharacterRight {
	color: var(--client-text);
	background-color: var(--client-background);
	font-family: Roboto, sans-serif;
	font-size: 16px;
	line-height: normal;
	text-align: right;
}

.eomEditorValues {
	display: flex;
	justify-content: left;
}

.lockdown {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.eomEditor {
	color: var(--client-text);
	background-color: var(--client-background);
	font-family: Consolas;
	font-size: 16px;
	min-height: 3em;
	width: 100%;
	line-height: normal;
	white-space: pre-wrap;
}

span {
	white-space: break-spaces;
}


.form-story {
	max-width: 100em;
}

.textarea {
	width: 100%;
	/* Opera/IE 8+ */
	background-color: var(--white-colour);
	color: var(--body-color-lower);
}

textarea:focus-visible {
	color: var(--body-color);
	outline: var(--body-color);
	outline-style: auto;
	outline-width: 1px;
}

.eom-box:focus-visible {
	color: var(--client-text);
}

.form-control-client {
	color: var(--body-color-lower);
	background-color: var(--title-background-light);
}

.form-control {
	background-color: var(--background-color);
	color: var(--body-text-color);
}

.form-control:focus {
	background-color: var(--background-color);
	color: var(--body-text-color-focus);
	border-color: var(--body-color);
}

.eomCommentEditor {
	background-color: var(--white-colour);
	border-color: var(--body-color);
	color: var(--black-color);
	font-family: Consolas;
	font-size: 16px;
	min-height: 3em;
	width: 100%;
	line-height: normal;
}

.eomCommentEditor:focus {
	outline: none !important;
	border: 1px solid var(--body-color);
	box-shadow: 0 0 10px var(--body-color);
}

.eomAssignEditor {
	background-color: var(--white-colour);
	border-color: var(--body-color);
	color: var(--black-color);
	font-family: Consolas;
	font-size: 16px;
	line-height: normal;
}

.eomAssignEditor:focus {
	outline: none !important;
	border: 1px solid red;
	box-shadow: 0 0 10px var(--body-color);
}

.eomTabs {
	margin-right: 0px;
	margin-left: 5px;
	color: var(--body-text-color);
	background-color: var(--client-background);

	.nav-link {
		color: var(--white-colour-light);
	}
}

.eomClientTabs {
	margin-right: 0px;
	margin-left: 5px;
	color: var(--client-text);
	background-color: var(--client-background);

	.nav-link {
		color: var(--white-colour-light);
	}
}

.eomIndicatorRow {
	color: var(--colour-client);
	background-color: var(--client-background);
	font-family: Consolas;
	font-size: 16px;
	min-height: 3em;
	width: 100%;
	line-height: normal;

}

.eomIndicator {
	color: var(--colour-client);
	background-color: var(--client-background);
	font-family: Consolas;
	font-size: 16px;
	min-height: 3em;
	width: 100%;
	padding: 0px;
}

.eomTabs:active {
	margin-right: 0px;
	margin-left: 5px;
	background-color: var(--client-background);
}

.nav-link.active {
	color: var(--colour-five) !important;
	background-color: var(--title-background) !important;
}

.maintabs .nav-link {
	margin-left: 2px;
	color: var(--body-text-colo) !important;
	background-color: var(--white-colour) !important;
	border-bottom: 1px solid var(--title-background) !important;
}

.maintabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
	color: var(--body-text-colo) !important;
	background-color: var(--white-colour) !important;
		border-bottom: 1px solid var(--title-background) !important;
}

.maintabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	color: var(--colour-five) !important;
	background-color: var(--title-background) !important;
}

.eomResourcePoolColumn {
	color: var(--client-text);
	background-color: var(--client-background);
	font-family: Consolas;
	font-size: 14px;
	line-height: normal;
	flex-wrap: wrap;
	margin-right: 0px;
}

.eomResourcePool {
	font-family: Consolas;
	font-size: 14px;
	line-height: normal;
	flex-wrap: nowrap;
	margin-right: 0px;
	--bs-gutter-x: 0;
}


.eomViewer {
	color: var(--client-text);
	background-color: var(--client-background);
	font-family: Consolas;
	font-size: 16px;
	width: 100%;
	line-height: normal;
}

.footer-card {
	color: var(--white-colour-light);
	margin: 0 auto;
	/* Added */
	float: none;
	/* Added */
	border-color: var(--border-color);
	background-color: var(--body-background);
}

.footer-card-header {
	color: var(--tag-color);
	border-color: var(--border-color);
	background-color: var(--title-background);
}

.panel-toggle {
	color: var(--white-colour-light);
	border-color: var(--border-color);
	background-color: var(--title-background);
}

.panel-toggle-header {
	padding: 6px 8px 6px 16px;
	text-decoration: none;
	font-size: 25px;
	color: var(--body-text-color);
	display: block;
}

.panel-toggle-header-np {
	padding: 0px;
	margin-bottom: 0rem;
}

p {
	margin: 0.25em
}

.alert-nm {
	margin-bottom: 0rem;
}

.menu-text {
	color: var(--color-light);
}

.flexbox-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	vertical-align: middle;
}

.flexbox-header {
	display: flex;
	flex-direction: row;
	justify-content: center;
	vertical-align: middle;
	width: 100%;
}

.my-icon {
	flex: 1;
	max-width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.inputBox .content {
	flex: 2;
}

.inputBox .icon {
	flex: 1;
	max-width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.alignright {
	margin-left: auto;
}

.message-col {
	max-width: 33%;
	max-height: 50vh;
	overflow-y: auto;
}

.message-box {
	margin-right: 40;
	margin-left: 40;
	margin-top: 10;
	padding-top: 20;
	padding-bottom: 20;
	background-color: '#68a0cf';
	border-radius: 10;
	border-width: 1;
	border-color: '#fff';
}

.eom-box {
	background-color: var(--client-background);
	border-color: var(--border-color);
	border-width: 3px;
	border-radius: 10px;
}

.eom-smallbox {
	max-height: 10em;
}

.eom-log {
	background-color: var(--black-color);
	border-color: var(--border-color);
	border-width: 3px;
	border-radius: 10px;
	min-width: 70rem;
	min-height: 30rem;
}

.container-wide {
	margin-right: 20px;
	margin-left: 40px;
}

.box {
	display: flex;
	flex-flow: column;
	min-height: 500px;
}

.deity-female {
	background-color: #ff59592e;
}

.deity-male {
	background-color: #598eff2e;
}

.deity-neuter {
	background-color: #81899c2e;
}

.deity-futanari {
	background-color: #db85d02e;
}

.deity-hermaphrodite {
	background-color: #7a5fa52e;
}

.card-player-menu {
	border-width: 0px;
	background-color: rgb(82 15 15);
}

.going-on-header {
	background-color: var(--title-background-light);
	border-width: 2px;
	border-color: var(--colour-one);
}

.going-on-header-editing {
	background-color: var(--title-text);
}

.menu-accordian {
	transition: 0.75s;
}

.card-themed-dark {
	color: var(--body-color-lower);
	background-color: var(--title-background);
	border-color: var(--border-color);
	border-width: 0px;
}

.sidebar-nav-item {
	background-color: rgb(82 15 15);
}

.panel-header {
	text-decoration: none;
	font-size: 25px;
	background-color: var(--border-color);
	background-color: var(--title-background);
	display: block;
}

.panel-header:hover {
	color: lightgrey;
}

.widthset {
	max-width: 64px;
	max-height: 200x;
}

.bigwidthset {
	max-width: 128px;
	max-height: 300x;
}

.heightSet {
	max-height: 64px;
}

.content .content-overlay {
	background-color: var(--title-background);
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
	-webkit-transition: all 0.4s ease-in-out 0s;
	-moz-transition: all 0.4s ease-in-out 0s;
	transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
	opacity: 0.7;
}

.content-details {
	position: absolute;
	text-align: center;
	padding-left: 1em;
	padding-right: 1em;
	width: 100%;
	top: 50%;
	left: 50%;
	opacity: 0;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
	top: 50%;
	left: 50%;
	opacity: 1;
	color: var(--white-colour);
}

.flatContainer {
	display: flex;
	flex-direction: row;
}

.container-eom {
	min-height: 20em;
}

.score-card-eom {
	min-height: 250px;
	min-width: 450px;
	max-width: 500px;
	border-width: 0.5px;
	border-width: 0.5px;
	border-color: var(--colour-one);
	border-radius: 6px;
}

.modal-body-eom {
	min-height: 250px;
	min-width: 450px;
	max-height: 600px;
}

.score-card-eom-wide {
	min-height: 250px;
	min-width: 600px;
}

.map_image {
	max-width: 200px;
	max-height: 200px;
}

.map_image_big {
	max-width: 800px;
	max-height: 800px;
}

.dialog-map {
	box-shadow: var(--black-color);
	background-color: var(--title-background);
	position: static;
	left: 20%;
	top: 10%;
}


.btn-eom:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
	background-color: var(--title-background);
	border-color: var(--title-background);
	box-shadow: var(--body-color);
}

.btn-eom:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
	background-color: var(--title-background);
	border-color: var(--body-color);
	box-shadow: var(--body-color);
}

.fa-eom {
	color: var(--title-background);
}

.fa-eom:hover {
	color: var(--colour-three);
}

.fa-eom-disabled {
	color: var(--title-background-light);
}

.fa-eom-inverse {
	color: var(--body-text-colour);
}

.fa-eom-inverse:hover {
	color: var(--colour-one);
}

.fa-eom-inverse-disabled {
	color: var(--colour-five);
}


.map-container {
	border-radius: 5px;
	border: 0px solid blue;
	background-color: var(--shadow3-color);
	width: 100%;
	height: 100%;
	margin: 0px;
	overflow: hidden;
}

.map-row {
	justify-content: center;
	width: 100%;
	background-color: var(--client-background);
	border-radius: 3px;
	max-width: 30vw;
	max-height: 60vw;
	height: 100%;
	max-height: 60vh;
	margin: 0px;
	border-radius: 6px;
}

.map-row-fixed {
	min-width: 600px;
	min-height: 600px;
}

.chartContainer {
	margin: 0 auto;
	max-width: 100%;
	position: relative;
	width: 360px;
}

.chartInner {
	background-color: var(--title-background);
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 22%;
	right: 20%;
	bottom: 20%;
	left: 20%;
	border-radius: 50%;
	padding: 1.25em 0;
	line-height: 120%;
	z-index: -1;
	/* Allow tooltips on top */
	overflow: hidden;
}

.chartStatus,
.chartValue {
	font-weight: bold;
}

.chartTarget,
.chartDaysLabel {
	font-size: 0.75em;
}

.chartDaysRemaining {
	color: #f90;
	font-weight: bold;
	margin-top: 0.25em;
}

.issue-column {
	border-top-style: dotted;
	border-right-style: solid;
	border-bottom-style: dotted;
	border-left-style: solid;

	border: 2px solid var(--title-background);
	flex-shrink: 1;
	flex-grow: 1;
	min-height: 60vh;
}

.btn-issue {
	min-width: 6em;
}

.eomissue-selected {
	background-color: var(--body-color);
	color: var(--white-colour);
}

.eomissue {
	margin-right: 2px;
}

.img-eom {
	border: 5px solid rgba(243, 111, 111, 0.589);
	max-width: 1024px;
	max-height: 600px;
}

.dropdown-menu-editor {
	max-height: 500px;
	overflow-y: scroll;
}

.dropdown-menu-short {
	height: 300px;
	overflow-y: scroll;
}

.btn-editor {
	opacity: 0.9;
	max-height: 2em;
	width: 100%;
	padding: 2px;
	width: 100%;
}

.userinput {
	width: 100%;
	background-color: var(--client-background);
	color: var(--client-text);
	border-color: var(--border-color);
	font-size: large;
}

.userinput-full {
	height: 90vh
}

.userinput:focus {
	background-color: var(--client-background);
	color: var(--client-text);

}

.row-even {
	background-color: var(--colour-six)
}

.pm {
	font-size: larger;
	font-family: Lato, Helvetica Neue, Helvetica, sans-serif;
	font-variant-ligatures: common-ligatures;
	line-height: 1.67;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: justify;
	padding-top: 10px;
	margin-bottom: 15px;
}

.h1m,
.h2m,
.h3m {
	font-family: Lato, Helvetica Neue, Helvetica, sans-serif;
	font-variant-ligatures: common-ligatures;
}

.h1m:after,
.h2m:after,
.h3m:after {
	content: "";
	display: block;
	position: relative;
	top: .33em;
	border-bottom: 2px solid hsla(0, 0%, 50%, .33);
	margin-bottom: 25px;
}

.toprighticon {
	position: absolute;
	right: 5px;
	top: 5px;
	z-index: 4;

}

.row-centered {
	align-items: center;
}

.professionheader {
	align-items: center;
}

.section-header {
	align-items: center;
	color: var(--colour-seven);
	background-color: var(--colour-one);
	border-color: var(--colour-three);
	border-radius: 10px;
	border: 3px;
	padding: 0.25rem;

	margin-top: 2rem;
}

.numbercolumn {
	padding: 1px;
}

.numberedit {
	width: 100%;
	-moz-appearance: textfield;
}

.xyz {
	cursor: not-allowed;
	pointer-events: none;
	color: #c0c0c0;
}

.userinput-col {
	flex-flow: row;
	margin: 0px;
	width: 100%;
	padding: 0px;

}

.userinput-col-btn {
	min-width: 90%;
	width: 90%;
	margin-left: 2px;
	margin-bottom: 1px;
	margin-top: 1px;
	height: 50%;
}

.userinput-btn {
	margin-top: 2px;
	margin-left: 2px;
	margin-bottom: 2px;
	height: 100%;
}

.ingredient-header {
	background-color: var(--colour-two);
	color: var(--colour-seven);
	border-radius: 5px;
	margin-left: -7px;
	margin-right: -2px;
}

.ingredient-border {
	border-radius: 5px;
	border: 3px solid var(--border-color);
	padding-left: 5px;
}

.right-col {
	align-items: right;
}

.bg-light {
	background-color: var(--tag-color) !important;
}

.badge-misc {
	background-color: #6328a7 !important;
	color: antiquewhite;
}

.badge-good {
	background-color: #28a745 !important;
	color: antiquewhite;
}

.badge-warn {
	background-color: #a72828 !important;
	color: antiquewhite;
}

.badge-info {
	background-color: #284aa7 !important;
	color: antiquewhite;
}

.scene-header {
	justify-content: center;
	width: 100%;
	background-color: var(--shadow2-color);
	border-radius: 3px;
	max-height: 60vw;
	height: 100%;
	max-height: 60vh;
	margin: 0px;
	border-radius: 6px;
	margin: 4px;
	padding: 4px;
	align-items: center;
	border-left: #a72828;
}

.participant-card {
	color: var(--white-colour-light);
	/* Added */
	float: none;
	border: 0px;
	padding: 0.25rem;
	background-color: var(--body-background);
}

.participant-card-body {
	color: var(--white-colour-light);
	/* Added */
	display: flex;
	align-items: center;
	justify-content: center;
	float: none;
	padding: 0.25rem;
	background-color: var(--body-background);
}

.participant-card-header {
	border-radius: 5px;
	border-color: var(--border-color);

	color: var(--white-colour-light);
	align-content: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.eom-scene {
	color: var(--white-colour-light);
}

.eom-scene {
	.nav-link {
		font-size: larger;
		font-family: 'Maven Pro', sans-serif;
		color: var(--white-colour-light);
	}

	.nav-tabs .nav-link.active {
		color: green;
		border-bottom: solid 1px white !important;
	}
}

.border-sizzle {
	border: solid 1px;
	border-radius: 5px;
	margin: 1px;
}

.accordion-body {
	color: var(--black-colour);
	background-color: var(--white-color);
	border: 0px;
	padding: 0px;
}

.accordion-button:focus {
	z-index: 3;
	border-color: red;
	outline: 0;
	box-shadow: red;
}

.accordion {
	--bs-accordion-bg: var(--border-color);

	--bs-accordion-color: var(--bs-body-color);
	--bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
	--bs-accordion-border-color: var(--bs-border-color);
	--bs-accordion-border-width: var(--bs-border-width);
	--bs-accordion-border-radius: var(--bs-border-radius);
	--bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
	--bs-accordion-btn-padding-x: 1.25rem;
	--bs-accordion-btn-padding-y: 1rem;
	--bs-accordion-btn-color: var(--bs-body-color);
	--bs-accordion-btn-bg: var(--bs-accordion-bg);
	--bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
	--bs-accordion-btn-icon-width: 1.25rem;
	--bs-accordion-btn-icon-transform: rotate(-180deg);
	--bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
	--bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23052c65%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
	--bs-accordion-btn-focus-border-color: #fe86d0;
	--bs-accordion-btn-focus-box-shadow: 0 0 0 0 var(--border-color);
	--bs-accordion-body-padding-x: 1.25rem;
	--bs-accordion-body-padding-y: 1rem;
	--bs-accordion-active-color: var(--bs-primary-text-emphasis);
	--bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-header {
	background-color: var(--border-color);
	color: var(--white-colour)
}

.accordion-button {
	background-color: var(--border-color);
	color: var(--tag-color);
}

.accordion-button:not(.collapsed) {
	background-color: var(--border-color);

	color: var(--contrast-text-color)
}

.bg-info {
	--bs-bg-opacity: 1;
	background-color: var(--client-background) !important;
}

.markdown-editor-header {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex: 1 0 100%;
	flex-direction: row;
	justify-content: left;
	width: 100%;
}

.btn-menu {
	width: max-content;
	margin: 2px;
	padding: 2px;
}

.btn-right {
	position: absolute;
	right: 10px;
	top: 5px;
	align-self: right;
	justify-self: right;
}

.badge {

	vertical-align: middle;
	align-content: center;

	.span {
		vertical-align: middle;
		align-content: center;
	}
}

.containerx {
	position: relative;
	width: 100%;
}

.containerx:hover .image {
	opacity: 0.3;
}

.containerx:hover .middle {
	opacity: 1;
}

.text {
	background-color: var(--colour-four);
	color: white;
	font-size: 16px;
	padding: 16px 32px;
}

.imagex {
	opacity: 1;
	display: block;
	transition: .5s ease;
	backface-visibility: hidden;
}

.middle {
	transition: 1.0s ease;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
}

.form-control::-webkit-input-placeholder {
	color: var(--title-background-light);
}

.largeFont {
	font-size: 25px;
}

.inline-text {
	color: var(--title-text);
}

.centered-row {
	justify-content: center
}

.form-check-input:checked {
	background-color: var(--colour-two);
	border-color: var(--colour-three);
}

tr {
	border-top: 1px solid #c6cbd1;
}

th,
td {
	padding: 6px 13px;
	border: 1px solid #dfe2e5;
}

table tr:nth-child(2n) {}